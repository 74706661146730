import React from 'react';

const moment = require('moment');

const Footer = props => {
    return (
        <div className={'c-footer u-flex-center'}>
            {`© In-tend Ltd 2006-${moment().format('YYYY')}`}
            {' | '}
            <a href="https://www.in-tend.co.uk/legal/" style={{ marginLeft: '5px' }} target="_blank">Legal</a>
        </div>
    );
}

export default Footer;