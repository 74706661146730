import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import ScrollTop from './components/layout/ScrollTop';
import registerServiceWorker from './registerServiceWorker';
import { store } from './store/index';
import { history } from './helpers/history';

import './main.css';

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <ScrollTop />
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();