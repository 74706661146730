export function authHeader(formData = false) {
    // return authorization header with jwt token
    let obj = JSON.parse(sessionStorage.getItem('auth'));

    if (obj && obj.token) {
        if (formData) {
            return {
                'Authorization': 'Bearer ' + obj.token
            };
        } else {
            return {
                'Authorization': 'Bearer ' + obj.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
        }
    } else {
        return {};
    }
}