import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginActions } from '../../actions/login.actions';
import Modal from '../layout/Modal';
import Header from '../layout/Header';
import ThemeSwitcher from '../layout/ThemeSwitcher';
import Footer from '../layout/Footer';

const Login = () => {

    useEffect(() => {
        document.querySelector('html').focus();
    }, []);

    const dispatch = useDispatch();
    const [value, setValue] = useState({});

    const handleChange = e => {
        e.persist();
        setValue(value => ({
            ...value,
            [e.target.name]: e.target.value
        }));
    }

    const handleBlur = e => {
        let input = e.target;

        const errorMessage = input.parentElement.querySelector('.js-error-message');
        if (input.value !== '') {

            if (input.checkValidity() && errorMessage) {
                errorMessage.remove();
            }
            else if (!input.checkValidity() && !errorMessage) {
                input.parentElement.insertAdjacentHTML('beforeend', '<p class="c-form-error c-form-error--inline js-error-message">' + input.dataset.errorMessage + '</p>');
            }
        }
        else if (errorMessage) {
            errorMessage.remove();
        }
    }

    const handleSubmit = e => {
        e.preventDefault();

        dispatch(loginActions.validateUser(value)).then(
            null,
            function () {
                toggle();
            }
        );
    }

    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    const [showModal, setShowModal] = useState(false);
    const toggle = () => setShowModal(!showModal);

    return (
        <>
            <Header />
            <main className="u-flex-center">
                <div className="u-container u-container--small">
                    <h1 className="u-branded-heading u-flex-center c-page-title c-page-title--home u-component-spacing--2x">Free tender contract portal</h1>
                    <p className="u-component-spacing">Search through thousands of tender opportunities for a range of value contracts from In-tends' system portals and external sources.</p>
                    <div className="c-login-forms u-component-spacing--2x">
                        <form className="c-login-forms__form u-component-spacing--2x" onSubmit={handleSubmit}>
                            <p className="c-form-error js-submission-error" hidden>
                                Email or password incorrect, please try again.
                            </p>
                            <div className="c-floating-label u-component-spacing">
                                <input className="c-floating-label__input js-validate" data-error-message="Please enter a valid email address" placeholder=" " type="email" id="labelledby-account-email" name="email" defaultValue={value.email} onChange={handleChange} onBlur={handleBlur} required autoFocus />
                                <label className="c-floating-label__label" htmlFor="labelledby-account-email">Email</label>
                            </div>
                            <div className="c-floating-label u-component-spacing">
                                <input className="c-floating-label__input js-password-field" placeholder=" " type={showPassword ? 'text' : 'password'} id="labelledby-account-password" name="password" defaultValue={value.password} onChange={handleChange} required />
                                <label className="c-floating-label__label" htmlFor="labelledby-account-password">Password</label>
                                <button className="c-floating-label__show-password" type="button" onClick={togglePassword}>{showPassword ? 'Hide' : 'Show'}</button>
                            </div>
                            <input type="submit" className="c-cta u-component-spacing" value="Login" />
                        </form>
                        <Link to='/forgot-password' className="c-login-forms__forgot-password u-component-spacing">I've forgotten my password</Link>
                    </div>
                    <div className="u-component-spacing--3x u-flex-center">
                        <h2 className="u-branded-underline">Contract sources</h2>
                    </div>
                    <ul className="c-tender-sources u-component-spacing--2x">
                        <li className="c-tender-sources__ojeu"><abbr title="Official Journal of the European Union">OJEU</abbr></li>
                        <li className="c-tender-sources__uk">
                            <ul className="u-list">
                                <li>UK Contracts Finder</li>
                                <li>UK Find a Tender</li>
                            </ul>
                        </li>
                        <li className="c-tender-sources__in-tend">
                            <svg viewBox="0 0 1785 546" xmlns="http://www.w3.org/2000/svg">
                                <use href="#in-tend-logo" />
                            </svg>
                        </li>
                    </ul>
                    <ThemeSwitcher />
                </div>
            </main>
            <Footer />
            <Modal show={showModal} message={'Incorrect login details were used. Please try again.'} type="error" toggle={toggle} />
        </>
    );
}

export default Login;