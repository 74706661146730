import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Modal = props => {

    let show = props.show, toggle = props.toggle, message = props.message, showTick = props.showTick, type = props.type;

    return (
        <div className={classnames('c-modal js-modal', show ? 'show' : '', showTick ? 'show-tick' : '')}>
            <div className={classnames('c-modal__inner', type === 'error' ? 'c-modal__inner--error' : type === 'success' ? 'c-modal__inner--success' : '')}>
                <div className="tick-icon" aria-hidden="true">
                    <div className="tick-icon__inner">
                        <span className="tick-icon__tick tick-icon__tick--long"></span>
                        <span className="tick-icon__tick tick-icon__tick--tip"></span>
                        <div className="tick-icon__ring"></div>
                        <div className="tick-icon__hide-corners"></div>
                    </div>
                </div>
                <strong className="js-modal-message c-modal__message">
                    {message}
                </strong>
                <button className="js-close-modal c-modal__close-button" onClick={toggle}>
                    <span className="u-visually-hidden">Close</span>
                </button>
            </div>
            <div className="c-modal__overlay js-close-modal"></div>
        </div>
    );
}

Modal.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.string,
    toggle: PropTypes.func,
    showTick: PropTypes.bool,
    type: PropTypes.string
};

Modal.defaultProps = {
    show: false,
    message: '',
    toggle: null,
    showTick: false,
    type: ''
};

export default Modal;