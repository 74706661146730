//                                                  //
//      In-tend General JavaScript Functions        //
//                                                  //


// Begin Imports


// Begin Functions
export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export function isValidUUID(str) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
}

export function setSessionVal(vKey, vValue) {
    sessionStorage.setItem(vKey, vValue);
}

export function getSessionVal(vKey) {
    return sessionStorage.getItem(vKey);
}