import { history } from '../helpers/history';

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT_USER';

export const loginActions = {
    validateUser,
    logoutUser
};

function request() { return { type: LOGIN_REQUEST }; }
function success(contact) { return { type: LOGIN_SUCCESS, contact }; }
function failure(error) { return { type: LOGIN_FAILURE, error }; }
function logout() { return { type: LOGOUT }; }

function validateUser(details) {
    return dispatch => {
        dispatch(request());

        return new Promise((resolve, reject) => {
            fetch('./Auth/ValidateLogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.token && data.token.length > 0) {
                        sessionStorage.setItem('auth', JSON.stringify(data));

                        dispatch(success(data.contact));
                        resolve(data.contact);

                        history.push('/search');
                    } else {
                        dispatch(failure("Oops!"));
                        reject("Oops!");
                    }
                }).catch(err => console.log("Error: ", err))
        });
    }
}

function logoutUser() {
    return dispatch => {
        sessionStorage.removeItem('auth');
        dispatch(logout());
        history.push('/sign-in');
    }
}