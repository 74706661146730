import React, { useState } from 'react';
import { history } from '../../helpers/history';
import Footer from '../layout/Footer';
import Header from '../layout/Header';
import Modal from '../layout/Modal';
import ThemeSwitcher from '../layout/ThemeSwitcher';

const ForgotPassword = () => {

    const [value, setValue] = useState({});

    const handleChange = e => {
        e.persist();

        setValue(value => ({
            ...value,
            [e.target.name]: e.target.value
        }))
    }

    const [showModal, setShowModal] = useState(false);
    const toggle = () => setShowModal(!showModal);

    const handleSubmit = e => {
        e.preventDefault();

        fetch(`./Auth/ForgotPassword?strEmail=${value.email}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    history.push('/password-sent');
                } else {
                    toggle();
                }
            })
            .catch(err => console.log("Error: ", err));
    }

    return (
        <>
            <Header />
            <main className="u-flex-center">
                <div className="u-container u-container--small">
                    <h1 className="u-flex-center c-page-title">Reset password</h1>
                    <p className="u-component-spacing u-text-center">Please enter your email address below and we'll help you get back into your account as soon as we can.</p>
                    <div className="c-login-forms u-component-spacing--2x">
                        <form className="c-login-forms__form u-component-spacing--2x" onSubmit={handleSubmit}>
                            <div className="c-floating-label u-component-spacing">
                                <input className="c-floating-label__input" placeholder=" " type="email" id="labelledby-account-email" name="email" defaultValue={value.email} onChange={handleChange} required />
                                <label className="c-floating-label__label" htmlFor="labelledby-account-email">Email</label>
                            </div>
                            <input type="submit" className="c-cta u-component-spacing" value="Send password reset" />
                        </form>
                    </div>
                    <ThemeSwitcher />
                </div>
            </main>
            <Footer />
            <Modal show={showModal} message={'Something went wrong...'} type="error" toggle={toggle} />
        </>
    );
}

export default ForgotPassword;