import React from 'react';
import { Route, Redirect } from 'react-router';

export const LayoutRoute = ({ layout, component, ...rest }) => (
    <Route {...rest} render={(props) =>
        sessionStorage.getItem('auth')
            ? React.createElement(layout, props, React.createElement(component, props))
            : <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
    } />
)
