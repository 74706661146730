import React from 'react';
import Footer from '../layout/Footer';
import Header from '../layout/Header';

const PasswordResetFailure = () => {
    return (
        <>
            <Header />
			<main className="u-flex-center">
				<div className="u-container u-container--small">
					<h1 className="u-flex-center c-page-title">Password Reset Unsuccessful</h1>
					<p className="u-text-center u-component-spacing">We're sorry, we were unable to reset your password. Please contact our support team and they should be able to help you.</p>
					<div className="u-flex-center u-component-spacing">
						<a className="c-cta" href="mailto:support@in-tend.co.uk">Email: support@in-tend.co.uk</a>
					</div>
				</div>
			</main>
			<Footer />
        </>
    );
}

export default PasswordResetFailure;