import React from 'react';

const SpinnerOverlay = () => {
    return (
        <div className="c-modal spinner js-spinner show">
            <div className="c-modal__inner c-modal__inner--transparent">
                <svg className="spinner__svg" width="66" height="66" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="spinner__svg-path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
            </div>
            <div className="c-modal__overlay js-close-modal"></div>
        </div>
    );
}

export default SpinnerOverlay;