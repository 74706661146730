import { authHeader } from '../helpers/auth-header';

export const TENDER_REQUEST = 'TENDER_REQUEST'
export const TENDER_SUCCESS = 'TENDER_SUCCESS'
export const TENDER_FAILURE = 'TENDER_FAILURE'

export const tenderActions = {
    getTenders
};

function request() { return { type: TENDER_REQUEST }; }
function success(tenders) { return { type: TENDER_SUCCESS, tenders }; }
function failure(error) { return { type: TENDER_FAILURE, error }; }

function getTenders(search, showAll, page) {
    return dispatch => {
        dispatch(request());

        return new Promise((resolve, reject) => {
            fetch(`./Tenders/GetTenders?strSearch=${search}&bShowAll=${showAll}&iPage=${page}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    setTimeout(() => {
                        dispatch(success(data));
                        resolve(data);
                    }, 1000);
                }).catch(err => {
                    console.log("Error: ", err);
                    dispatch(failure(err));
                    reject(err);
                })
        });
    }
}