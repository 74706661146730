import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isValidUUID } from '../../helpers/general';
import { history } from '../../helpers/history';
import Footer from '../layout/Footer';
import Header from '../layout/Header';
import ThemeSwitcher from '../layout/ThemeSwitcher';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PasswordReset = () => {

    let query = useQuery();
    const guid = query.get('resetkey');

    useEffect(() => {
        if (guid.length === 0) {
            history.push('/sign-in');
        } else if (isValidUUID(guid)) {
            fetch(`./Auth/VerifyPasswordReset?obGuid=${guid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        history.push('/sign-in');
                    }
                })
                .catch(err => console.log('Err:', err));
        } else {
            history.push('/sign-in');
        }
    }, [guid]);

    const [value, setValue] = useState({});

    const handleChange = e => {
        e.persist();

        setValue(value => ({
            ...value,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = e => {
        e.preventDefault();

        fetch(`./Auth/ResetPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ 'Guid': guid, 'Password': value.password })
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    history.push('/password-success');
                } else {
                    history.push('/password-failure');
                }
            })
            .catch(err => console.log("Error: ", err));
    }

    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    return (
        <>
            <Header />
            <main className="u-flex-center">
                <div className="u-container u-container--small">
                    <h1 className="u-flex-center c-page-title">Reset Password</h1>
                    <p className="u-component-spacing">Please enter a new password for your account.</p>
                    <div className="c-login-forms u-component-spacing--2x">
                        <form className="c-login-forms__form u-component-spacing--2x" onSubmit={handleSubmit}>
                            <div className="c-floating-label u-component-spacing">
                                <input className="c-floating-label__input js-password-field js-validate" data-error-message="Please enter a valid password" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}" placeholder=" " type={showPassword ? 'text' : 'password'} id="labelledby-account-password" name="password" defaultValue={value.password} onChange={handleChange} required />
                                <label className="c-floating-label__label" htmlFor="labelledby-account-password">Choose a new password</label>
                                <button className="c-floating-label__show-password" type="button" onClick={togglePassword}>{showPassword ? 'Hide' : 'Show'}</button>
                            </div>
                            <div className="c-password-requirements u-component-spacing">
                                <p className="c-password-requirements__text">Passwords must contain:</p>
                                <ul className="c-password-requirements__list">
                                    <li className="c-password-requirements__list-item">At least 8 characters</li>
                                    <li className="c-password-requirements__list-item">At least 1 uppercase letter</li>
                                    <li className="c-password-requirements__list-item">At least 1 lowercase letter</li>
                                    <li className="c-password-requirements__list-item">At least 1 number</li>
                                    <li className="c-password-requirements__list-item">At least 1 special character</li>
                                </ul>
                            </div>
                            <input type="submit" className="c-cta u-component-spacing" value="Reset Password" />
                        </form>
                    </div>
                    <ThemeSwitcher />
                </div>
            </main>
            <Footer />
        </>
    );
}

export default PasswordReset;