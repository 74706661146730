import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../layout/Footer';
import Header from '../layout/Header';

const PasswordResetSuccess = () => {
    return (
        <>
            <Header />
			<main className="u-flex-center">
				<div className="u-container u-container--small">
					<h1 className="u-flex-center c-page-title">Password Reset Successful</h1>
					<p className="u-text-center u-component-spacing">Your password has now been reset. Please try accessing your account again using your new password.</p>
					<div className="u-flex-center u-component-spacing">
						<Link to='/sign-in' className="c-cta">Return to login page</Link>
					</div>
				</div>
			</main>
			<Footer />
        </>
    );
}

export default PasswordResetSuccess;