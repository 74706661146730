import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from '../actions/login.actions';

let auth = JSON.parse(sessionStorage.getItem('auth'));
const initialState = auth ? { isAuthenticated: true, contact: auth.contact } : {};

export default function login(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                isAuthenticated: false
            })
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated: true,
                contact: action.contact
            })
        case LOGIN_FAILURE:
            return Object.assign({}, state)
        case LOGOUT:
            return Object.assign({}, state, {
                isAuthenticated: false,
                contact: {}
            })
        default:
            return state
    }
}