import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { tenderActions } from '../actions/tenders.actions';
import { getSessionVal, setSessionVal } from '../helpers/general';
import SpinnerOverlay from './layout/SpinnerOverlay';
import ThemeSwitcher from './layout/ThemeSwitcher';

const moment = require('moment');

const ListPage = () => {

    let location = useLocation();
    let { search } = location.state || { search: '' };
    let { showAll } = location.state || { showAll: false };

    const dispatch = useDispatch();

    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        var vPage = parseInt(getSessionVal('TendersPageNumber') ?? 1);
        setPageNumber(vPage);
    }, []);

    useEffect(() => {
        setSessionVal('TendersPageNumber', pageNumber); // Store the new pageNumber 

        dispatch(tenderActions.getTenders(search, showAll, pageNumber)).then(data => {
            if (data.length) {
                var vTotal = data[0].totalCount;
                var vPages = vTotal / 20;
                vPages = Math.round(vPages);
                setTotalPages(vPages);
            }
        });
    }, [dispatch, search, showAll, pageNumber]);

    const { tenders } = useSelector(state => state);

    useEffect(() => {
        const resultsList = document.querySelector('.js-results-list');
        const viewChangerButtons = document.querySelectorAll('.js-view-changer');
        const activeClass = 'c-view-changer__button--active';

        const viewMode = getSessionVal('ViewMode') ?? 'list';

        Array.from(viewChangerButtons).map(button => {
            if (button.dataset.viewMode === viewMode) {
                button.classList.add(activeClass);
                resultsList.dataset.viewMode = viewMode;
            }

            button.addEventListener('click', () => {
                document.querySelector('.' + activeClass).classList.remove(activeClass);
                button.classList.add(activeClass);
                resultsList.dataset.viewMode = button.dataset.viewMode;
                setSessionVal('ViewMode', button.dataset.viewMode);
            });
            return null;
        });
    });

    const disableNextPrevious = (vIsNext) => {
        if (vIsNext) {
            return pageNumber >= totalPages;
        } else {
            return pageNumber <= 1; // Are we on the first page - if so, disabled the previous button
        }
    }

    const handleNextPrevious = (vIsNext) => {
        if (vIsNext) {
            setPageNumber(prevPage => prevPage + 1);
        } else {
            setPageNumber(prevPage => prevPage - 1);
        }

        window.scrollTo(0, 0);
    }

    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <main>
            {!tenders.isLoading &&
                <>
                    <div className="u-container">
                        <div className="c-list-header">
                            <div className="c-list-header__content">
                                <h1 className="c-page-title u-component-spacing--3x">Search results for '{search}'</h1>
                                <p className="u-component-spacing">
                                    {
                                        tenders.tenders.length > 0 ? `${tenders.tenders[0].totalCount} results found` : ''
                                    }
                                    {
                                        tenders.tenders.length > 0 ? ` - Showing ${(pageNumber - 1) * 20 + 1} to ${(pageNumber * 20) >= tenders.tenders[0].totalCount ? tenders.tenders[0].totalCount : pageNumber * 20}` : ''
                                    }
                                </p>
                            </div>
                            <Link to='/search' className="c-list-header__search-button">Search again</Link>
                        </div>
                        <div data-view-mode="list" className="c-results js-results-list u-component-spacing--2x">
                            <div className="c-view-changer">
                                <p>Display mode:</p>
                                <button className="c-view-changer__button js-view-changer" data-view-mode="list">
                                    <svg className="c-view-changer__button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="14" height="14"><path className="c-view-changer__button-icon-path" d="M18 7.999a.998.998 0 0 0-.998-.998H.998A.998.998 0 0 0 0 7.999v2.002c0 .552.447.998.998.998h16.004a.998.998 0 0 0 .998-.998V7.999ZM18 .998A.998.998 0 0 0 17.002 0H.998A.998.998 0 0 0 0 .998V3c0 .552.447.998.998.998h16.004A.998.998 0 0 0 18 3V.998ZM18 15a.998.998 0 0 0-.998-.998H.998A.998.998 0 0 0 0 15v2.002c0 .552.447.998.998.998h16.004a.998.998 0 0 0 .998-.998V15Z" /></svg>
                                    <span className="c-view-changer__button-text">List</span>
                                </button>
                                <button className="c-view-changer__button js-view-changer" data-view-mode="grid">
                                    <svg className="c-view-changer__button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="14" height="14"><path className="c-view-changer__button-icon-path" d="M9.435 1.794C9.435.804 8.63 0 7.64 0H1.794C.804 0 0 .804 0 1.794V7.64c0 .991.804 1.795 1.794 1.795H7.64c.991 0 1.795-.804 1.795-1.795V1.794ZM22 1.794C22 .804 21.197 0 20.206 0h-5.847c-.99 0-1.794.804-1.794 1.794V7.64c0 .991.804 1.795 1.794 1.795h5.847c.99 0 1.794-.804 1.794-1.795V1.794ZM9.435 14.36c0-.991-.804-1.795-1.795-1.795H1.794C.804 12.565 0 13.37 0 14.36v5.847C0 21.196.804 22 1.794 22H7.64c.991 0 1.795-.803 1.795-1.794v-5.847ZM22 14.36c0-.991-.803-1.795-1.794-1.795h-5.847c-.99 0-1.794.804-1.794 1.794v5.847c0 .99.804 1.794 1.794 1.794h5.847c.99 0 1.794-.803 1.794-1.794v-5.847Z" /></svg>
                                    <span className="c-view-changer__button-text">Grid</span>
                                </button>
                            </div>
                            {
                                tenders.tenders.map((collection, i) => {
                                    return (
                                        <section className="c-results-list" key={i}>
                                            <h2 className="c-results-list__heading">{moment(collection.month, 'MM').format('MMMM')} {collection.year}</h2>
                                            <ol className="c-results-list__list u-component-spacing">
                                                {
                                                    collection.tenders.map((tender, j) => {
                                                        return (
                                                            <li className="c-results-list__list-item" key={j}>
                                                                <Link to={`/tender-details/${tender.guid}`} className="c-result">
                                                                    <div className="c-result__content">
                                                                        <h3 className="c-result__heading" dangerouslySetInnerHTML={{ __html: tender.title }} />
                                                                        <p dangerouslySetInnerHTML={{ __html: tender.entityName }} />

                                                                        <dl className="c-result__summary u-component-spacing">
                                                                            <div className="c-result__summary-item c-result__summary-item--published">
                                                                                <dt className="c-result__summary-term">Published:</dt>
                                                                                <dd className="c-result__summary-definition">{moment(tender.dateAdded).isValid() ? moment(tender.dateAdded).format('DD/MM/YYYY') : ''}</dd>
                                                                            </div>
                                                                            <div className="c-result__summary-item c-result__summary-item--deadline">
                                                                                <dt className="c-result__summary-term">Deadline:</dt>
                                                                                <dd className="c-result__summary-definition">{moment(tender.docsAvailableUntil).isValid() ? moment(tender.docsAvailableUntil).format('DD/MM/YYYY') : ''}</dd>
                                                                            </div>
                                                                            <div className="c-result__summary-item c-result__summary-item--location">
                                                                                <dt className="c-result__summary-term">Location:</dt>
                                                                                <dd className="c-result__summary-definition">{tender.country ? tender.country : 'Not Specified'}</dd>
                                                                            </div>
                                                                            <div className="c-result__summary-item c-result__summary-item--value">
                                                                                <dt className="c-result__summary-term">Value:</dt>
                                                                                <dd className="c-result__summary-definition">
                                                                                    {
                                                                                        tender.valueTo > 0 ?
                                                                                            `${formatter.format(tender.valueFrom)} to ${formatter.format(tender.valueTo)}`
                                                                                            : 'Not Specified'
                                                                                    }
                                                                                </dd>
                                                                            </div>
                                                                        </dl>
                                                                    </div>
                                                                    <p className="c-result__footer">
                                                                        <span className="c-result__prompt">Find out more</span>
                                                                    </p>
                                                                </Link>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ol>
                                        </section>
                                    )
                                })
                            }
                        </div>
                        <div className="c-list-pagination">
                            <button className="c-list-pagination__button c-list-pagination__button--previous" onClick={() => handleNextPrevious(false)} hidden={disableNextPrevious(false)}>Previous</button>
                            <button className="c-list-pagination__button c-list-pagination__button--next" onClick={() => handleNextPrevious(true)} hidden={disableNextPrevious(true)}>Next</button>
                        </div>

                    </div>
                    <ThemeSwitcher />
                </>
            }
            {tenders.isLoading &&
                <SpinnerOverlay />
            }
        </main>
    );
}

export default ListPage;