import React, { useState } from 'react';
import { history } from '../../helpers/history';
import Footer from '../layout/Footer';
import Header from '../layout/Header';
import Modal from '../layout/Modal';
import ThemeSwitcher from '../layout/ThemeSwitcher';

const Register = () => {

    const [value, setValue] = useState({});

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const toggleSuccess = () => {
        setShowSuccessModal(!showSuccessModal);
        history.push('/sign-in');
    }

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorText, setErrorText] = useState('');
    const toggleError = () => setShowErrorModal(!showErrorModal);

    const handleChange = e => {
        e.persist();
        setValue(value => ({
            ...value,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = e => {
        e.preventDefault();

        fetch('./Auth/RegisterSupplier', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(value)
        })
            .then(response => response.json())
            .then(data => {
                if (data.Success) {
                    setShowSuccessModal(true);
                } else {
                    setErrorText(data.Error);
                    setShowErrorModal(true);
                }
            })
            .catch(err => console.log("Error: ", err))
    }

    const handleBlur = e => {
        let input = e.target;
        if (input.classList.contains('js-validate')) {
            const errorMessage = input.parentElement.querySelector('.js-error-message');
            if (input.value !== '') {

                if (input.checkValidity() && errorMessage) {
                    errorMessage.remove();
                }
                else if (!input.checkValidity() && !errorMessage) {
                    input.parentElement.insertAdjacentHTML('beforeend', '<p class="c-form-error c-form-error--inline js-error-message">' + input.dataset.errorMessage + '</p>');
                }
            }
            else if (errorMessage) {
                errorMessage.remove();
            }
        }
    }

    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    return (
        <>
            <Header />
            <main>
                <div className="u-container">
                    <h1 className="c-page-title u-component-spacing--3x">Create an account</h1>
                    <div className="c-login-forms c-login-forms--grid-display u-component-spacing--2x">
                        <form className="c-login-forms__form" onSubmit={handleSubmit}>
                            <div className="c-login-forms__form-section u-component-spacing--2x">
                                <h2 className="c-broken-underline">Your details</h2>
                                <div className="c-floating-label u-component-spacing">
                                    <input className="c-floating-label__input js-validate" data-error-message="Please enter a valid name" pattern="[A-Za-z]+" placeholder=" " type="text" id="labelledby-firstname" name="firstName" defaultValue={value.firstName} onChange={handleChange} onBlur={handleBlur} required />
                                    <label className="c-floating-label__label" htmlFor="labelledby-firstname">First Name</label>
                                </div>
                                <div className="c-floating-label u-component-spacing">
                                    <input className="c-floating-label__input js-validate" data-error-message="Please enter a valid name" pattern="[A-Za-z]+" placeholder=" " type="text" id="labelledby-surname" name="lastName" defaultValue={value.lastName} onChange={handleChange} onBlur={handleBlur} required />
                                    <label className="c-floating-label__label" htmlFor="labelledby-surname">Last Name</label>
                                </div>
                                <div className="c-floating-label u-component-spacing">
                                    <input className="c-floating-label__input js-validate" data-error-message="Please enter a valid email address" placeholder=" " type="email" id="labelledby-create-email" name="email" defaultValue={value.email} onChange={handleChange} onBlur={handleBlur} required />
                                    <label className="c-floating-label__label" htmlFor="labelledby-create-email">Email</label>
                                </div>
                                <div className="c-floating-label u-component-spacing">
                                    <input className="c-floating-label__input" minLength="2" placeholder=" " type="text" id="labelledby-company-name" name="companyName" defaultValue={value.companyName} onChange={handleChange} onBlur={handleBlur} required />
                                    <label className="c-floating-label__label" htmlFor="labelledby-company-name">Company Name</label>
                                </div>
                                <div className="c-floating-label u-component-spacing">
                                    <input className="c-floating-label__input"  placeholder=" " type="text" id="labelledby-company-reg" name="companyReg" defaultValue={value.companyReg} onChange={handleChange} onBlur={handleBlur} />
                                    <label className="c-floating-label__label" htmlFor="labelledby-company-reg">Company Reg Number</label>
                                </div>
                                <fieldset className="c-login-forms__fieldset u-component-spacing--2x">
                                    <legend>Company Address</legend>
                                    <div className="c-floating-label">
                                        <input className="c-floating-label__input js-validate" data-error-message="Please enter a valid street address" minLength="2" placeholder=" " type="text" id="labelledby-addr-line1" name="address1" defaultValue={value.address1} onChange={handleChange} onBlur={handleBlur} required />
                                        <label className="c-floating-label__label" htmlFor="labelledby-addr-line1">Address Line 1</label>
                                    </div>
                                    <div className="c-floating-label u-component-spacing">
                                        <input className="c-floating-label__input" placeholder=" " type="text" id="labelledby-addr-line2" name="address2" defaultValue={value.address2} onChange={handleChange} onBlur={handleBlur} />
                                        <label className="c-floating-label__label" htmlFor="labelledby-addr-line2">Address Line 2</label>
                                    </div>
                                    <div className="c-floating-label u-component-spacing">
                                        <input className="c-floating-label__input js-validate" data-error-message="Please enter a valid town/city" minLength="3" placeholder=" " type="text" id="labelledby-addr-town" name="town" defaultValue={value.town} onChange={handleChange} onBlur={handleBlur} required />
                                        <label className="c-floating-label__label" htmlFor="labelledby-addr-town">Town / City</label>
                                    </div>
                                    <div className="c-floating-label u-component-spacing">
                                        <input className="c-floating-label__input" placeholder=" " type="text" id="labelledby-addr-postcode" name="postcode" defaultValue={value.postcode} onChange={handleChange} onBlur={handleBlur} required />
                                        <label className="c-floating-label__label" htmlFor="labelledby-addr-postcode">Postcode</label>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="c-login-forms__form-section u-component-spacing--2x">
                                <h2 className="c-broken-underline">Choose a password</h2>
                                <div className="c-floating-label u-component-spacing">
                                    <input className="c-floating-label__input js-password-field js-validate" data-error-message="Please enter a valid password" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}" placeholder=" " type={showPassword ? 'text' : 'password'} id="labelledby-create-password" name="password" defaultValue={value.password} onChange={handleChange} onBlur={handleBlur} required />
                                    <label className="c-floating-label__label" htmlFor="labelledby-create-password">Create Password</label>
                                    <button className="c-floating-label__show-password" type="button" onClick={togglePassword}>{showPassword ? 'Hide' : 'Show'}</button>
                                </div>
                                <div className="c-password-requirements u-component-spacing">
                                    <p className="c-password-requirements__text">Passwords must contain:</p>
                                    <ul className="c-password-requirements__list">
                                        <li className="c-password-requirements__list-item">At least 8 characters</li>
                                        <li className="c-password-requirements__list-item">At least 1 uppercase letter</li>
                                        <li className="c-password-requirements__list-item">At least 1 lowercase letter</li>
                                        <li className="c-password-requirements__list-item">At least 1 number</li>
                                        <li className="c-password-requirements__list-item">At least 1 special character</li>
                                    </ul>
                                </div>
                                <input type="submit" className="c-cta u-component-spacing--2x" value="Create Account" />
                            </div>
                        </form>
                    </div>
                    <ThemeSwitcher />
                </div>
            </main>
            <Footer />
            <Modal show={showSuccessModal} message={'Success! You can now log in.'} type="success" toggle={toggleSuccess} />
            <Modal show={showErrorModal} message={errorText} type="error" toggle={toggleError} />
        </>
    );
}

export default Register;