import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { authHeader } from '../helpers/auth-header';
import ThemeSwitcher from './layout/ThemeSwitcher';

const moment = require('moment');

const TenderDetails = () => {

    const { guid } = useParams();

    const history = useHistory();

    const [tenderDetails, setTenderDetails] = useState({});
    const [tenderCPVCodes, setTenderCPVCodes] = useState([]);

    useEffect(() => {
        fetch(`./Tenders/GetTenderDetails?strGUID=${guid}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                setTenderDetails(data);
            })
            .catch(error => {
                console.log('Error: ', error);
            })

        fetch(`./Tenders/GetTenderCPVCodes?strGUID=${guid}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                setTenderCPVCodes(data);
            })
            .catch(error => {
                console.log('Error: ', error);
            })
    }, [guid]);

    function getDuration(openDate, closeDate) {
        let vStart = moment(openDate);
        let vFinish = moment(closeDate);

        // Start by using Years
        let vDiff = '';

        vDiff = vFinish.diff(vStart, 'years', true);
        if (parseInt(vDiff) < 1) {
            vDiff = vFinish.diff(vStart, 'months') + ' Months';
        } else {
            vDiff = Math.round(vDiff * 100) / 100 + ' Years';
        }

        return vDiff;
    }

    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <main>
            <div className="u-container">
                <button
                    class="js-back-to-results c-back-button u-component-spacing"
                    onClick={
                        () => { history.goBack() }
                    }
                >
                    {'Back to results'}
                </button>
                <h1 className="c-page-title u-component-spacing--3x" dangerouslySetInnerHTML={{ __html: tenderDetails.title }} />
                <p className="u-component-spacing" dangerouslySetInnerHTML={{ __html: tenderDetails.entityName }} />

                <div className="l-contract-detail-page">
                    <section className="l-contract-detail-page__section">
                        <h2 className="c-broken-underline u-component-spacing--3x">Description</h2>
                        <p className="u-container--no-padding u-container--small u-component-spacing--2x" dangerouslySetInnerHTML={{ __html: tenderDetails.tenderDescription }} />
                    </section>

                    <section className="l-contract-detail-page__section l-contract-detail-page__section--summary">
                        <h2 className="c-broken-underline u-component-spacing--3x">Contract Summary</h2>
                        <dl className="c-contract-details u-component-spacing--2x">
                            {tenderDetails.entityName &&
                                <>
                                    <dt className="c-contract-details__term">Contracting Authority:</dt>
                                    <dd className="c-contract-details__description" dangerouslySetInnerHTML={{ __html: tenderDetails.entityName }} />
                                </>
                            }
                            {tenderDetails.title &&
                                <>
                                    <dt className="c-contract-details__term">Title:</dt>
                                    <dd className="c-contract-details__description">{tenderDetails.title}</dd>
                                </>
                            }
                            <dt className="c-contract-details__term">Value:</dt>
                            <dd className="c-contract-details__description">
                                {
                                    tenderDetails.valueTo > 0 ?
                                        `${formatter.format(tenderDetails.valueFrom)} to ${formatter.format(tenderDetails.valueTo)}`
                                        : 'Not Specified'
                                }
                            </dd>
                            {tenderDetails.referenceNumber &&
                                <>
                                    <dt className="c-contract-details__term c-contract-details__term--full-width">Reference:</dt>
                                    <dd className="c-contract-details__description c-contract-details__description--full-width">{tenderDetails.referenceNumber}</dd>
                                </>
                            }
                            {tenderDetails.contractLink &&
                                <>
                                    <dt className="c-contract-details__term">Link to Contract:</dt>
                                    <dd className="c-contract-details__description">
                                        <a href={tenderDetails.contractLink} target="_blank">{tenderDetails.contractLink}</a>
                                    </dd>
                                </>
                            }
                            {tenderDetails.portalLink &&
                                <>
                                    <dt className="c-contract-details__term">To express interest:</dt>
                                    <dd className="c-contract-details__description">
                                        <a href={tenderDetails.portalLink} target="_blank">{tenderDetails.portalLink}</a>
                                    </dd>
                                </>
                            }
                        </dl>

                        <div className="u-flex-center u-component-spacing--2x">
                            <h3 className="u-branded-underline">Key Dates</h3>
                        </div>
                        <dl className="c-contract-details u-component-spacing--2x">
                            {moment(tenderDetails.dateAdded).isValid() && moment(tenderDetails.dateAdded).isAfter('01-01-1970') &&
                                <>
                                    <dt className="c-contract-details__term">Date Added:</dt>
                                    <dd className="c-contract-details__description">{moment(tenderDetails.dateAdded).isValid() && moment(tenderDetails.dateAdded).isAfter('01-01-1970') ? moment(tenderDetails.dateAdded).format('Do MMMM YYYY') : ''}</dd>
                                </>
                            }
                            {moment(tenderDetails.docsAvailableUntil).isValid() && moment(tenderDetails.docsAvailableUntil).isAfter('01-01-1970') &&
                                <>
                                    <dt className="c-contract-details__term">Application Deadline:</dt>
                                    <dd className="c-contract-details__description">{moment(tenderDetails.docsAvailableUntil).isValid() && moment(tenderDetails.docsAvailableUntil).isAfter('01-01-1970') ? moment(tenderDetails.docsAvailableUntil).format('Do MMMM YYYY') : ''}</dd>
                                </>
                            }
                            {moment(tenderDetails.openDate).isValid() && moment(tenderDetails.openDate).isAfter('01-01-1970') &&
                                <>
                                    <dt className="c-contract-details__term">Start Date:</dt>
                                    <dd className="c-contract-details__description">{moment(tenderDetails.openDate).isValid() && moment(tenderDetails.openDate).isAfter('01-01-1970') ? moment(tenderDetails.openDate).format('Do MMMM YYYY') : ''}</dd>
                                </>
                            }
                            {moment(tenderDetails.closeDate).isValid() && moment(tenderDetails.closeDate).isAfter('01-01-1970') &&
                                <>
                                    <dt className="c-contract-details__term">End Date:</dt>
                                    <dd className="c-contract-details__description">{moment(tenderDetails.closeDate).isValid() && moment(tenderDetails.closeDate).isAfter('01-01-1970') ? moment(tenderDetails.closeDate).format('Do MMMM YYYY') : ''}</dd>
                                </>
                            }
                            {moment(tenderDetails.openDate).isAfter('01-01-1970') &&
                                <>
                                    <dt className="c-contract-details__term">Duration:</dt>
                                    <dd className="c-contract-details__description">{moment(tenderDetails.openDate).isAfter('01-01-1970') ? getDuration(tenderDetails.openDate, tenderDetails.closeDate) : ''}</dd>
                                </>
                            }
                        </dl>

                        <div className="u-flex-center u-component-spacing--2x">
                            <h3 className="u-branded-underline">Contract Location</h3>
                        </div>
                        <dl className="c-contract-details u-component-spacing--2x">
                            {tenderDetails.deliveryLocation &&
                                <>
                                    <dt className="c-contract-details__term">Delivery Location:</dt>
                                    <dd className="c-contract-details__description">{tenderDetails.deliveryLocation}</dd>
                                </>
                            }
                            {tenderDetails.contractLocation &&
                                <>
                                    <dt className="c-contract-details__term">Location of Contract:</dt>
                                    <dd className="c-contract-details__description">{tenderDetails.contractLocation}</dd>
                                </>
                            }
                        </dl>

                        <div className="u-flex-center u-component-spacing--2x">
                            <h3 className="u-branded-underline">Buyer Information</h3>
                        </div>
                        <dl className="c-contract-details u-component-spacing--2x">
                            {tenderDetails.tenderContactName &&
                                <>
                                    <dt className="c-contract-details__term">Contact name:</dt>
                                    <dd className="c-contract-details__description">{tenderDetails.tenderContactName}</dd>
                                </>
                            }
                            {(tenderDetails.address1 || tenderDetails.address2 || tenderDetails.address3 || tenderDetails.address4 || tenderDetails.postCode || tenderDetails.country) &&
                                <>
                                    <dt className="c-contract-details__term">Address:</dt>
                                    <dd className="c-contract-details__description">
                                        <div className="c-address">
                                            {tenderDetails.address1 &&
                                                <span className="c-address__address-line">{tenderDetails.address1}</span>
                                            }
                                            {tenderDetails.address2 &&
                                                <span className="c-address__address-line">{tenderDetails.address2}</span>
                                            }
                                            {tenderDetails.address3 &&
                                                <span className="c-address__address-line">{tenderDetails.address3}</span>
                                            }
                                            {tenderDetails.address4 &&
                                                <span className="c-address__address-line">{tenderDetails.address4}</span>
                                            }
                                            {tenderDetails.postCode &&
                                                <span className="c-address__address-line">{tenderDetails.postCode}</span>
                                            }
                                            {tenderDetails.country &&
                                                <span className="c-address__address-line">{tenderDetails.country}</span>
                                            }
                                        </div>
                                    </dd>
                                </>
                            }
                            {tenderDetails.tenderContactTelephone &&
                                <>
                                    <dt className="c-contract-details__term c-contract-details__term--full-width">Telephone:</dt>
                                    <dd className="c-contract-details__description c-contract-details__description--full-width">{tenderDetails.tenderContactTelephone}</dd>
                                </>
                            }
                            {tenderDetails.tenderContactEmail &&
                                <>
                                    <dt className="c-contract-details__term c-contract-details__term--full-width">Email:</dt>
                                    <dd className="c-contract-details__description c-contract-details__description--full-width">{tenderDetails.tenderContactEmail}</dd>
                                </>
                            }
                        </dl>

                        {tenderCPVCodes && tenderCPVCodes.length > 0 &&
                            <>
                                <div className="u-flex-center u-component-spacing--2x">
                                    <h3 className="u-branded-underline">CPV Codes</h3>
                                </div>
                                {
                                    tenderCPVCodes.map((code, i) => {
                                        return (
                                            <dl className="c-contract-details c-contract-details--fixed-width u-component-spacing--2x" key={i}>
                                                <dt className="c-contract-details__term">Code:</dt>
                                                <dd className="c-contract-details__description">{code.code}</dd>
                                                <dt className="c-contract-details__term">Title:</dt>
                                                <dd className="c-contract-details__description">{code.description}</dd>
                                                <dt className="c-contract-details__term c-contract-details__term--full-width">Parent Description:</dt>
                                                <dd className="c-contract-details__description c-contract-details__description--full-width">{code.parentDescription}</dd>
                                            </dl>
                                        )
                                    })
                                }
                            </>
                        }
                    </section>

                    {tenderDetails.additionalText &&
                        <section className="l-contract-detail-page__section">
                            <h2 className="c-broken-underline u-component-spacing--3x">Additional Information</h2>
                            <p className="u-container--no-padding u-container--small u-component-spacing--2x" dangerouslySetInnerHTML={{ __html: tenderDetails.additionalText }} />
                        </section>
                    }
                </div>
                <ThemeSwitcher />
            </div>
        </main>
    );
}

export default TenderDetails;