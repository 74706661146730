import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware  from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from "../reducers/index";
import { createLogger } from 'redux-logger';

let middleware = [];
const loggerMiddleware = createLogger();

if (process.env.NODE_ENV === 'development') {
	middleware = [...middleware, thunkMiddleware, loggerMiddleware];
} else {
	middleware = [...middleware, thunkMiddleware];
}

export const store = createStore(
	reducer,
	composeWithDevTools(
		applyMiddleware(
			...middleware
		)
	)
);