import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../layout/Footer';
import Header from '../layout/Header';

const PasswordResetSent = () => {
    return (
        <>
            <Header />
			<main className="u-flex-center">
				<div className="u-container u-container--small">
					<h1 className="u-flex-center c-page-title">Email Sent</h1>
					<p className="u-text-center u-component-spacing">Please check your email inbox for instructions as to how you can reset your password.</p>
					<div className="u-flex-center u-component-spacing">
						<Link to='/sign-in' className="c-cta">Return to home page</Link>
					</div>
				</div>
			</main>
			<Footer />
        </>
    );
}

export default PasswordResetSent;