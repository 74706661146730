import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Search from './components/Search';
import ListPage from './components/ListPage';
import Login from './components/account/Login';
import Register from './components/account/Register';
import { LayoutRoute } from './components/layout/LayoutRoute';
import ForgotPassword from './components/account/ForgotPassword';
import PasswordResetSent from './components/account/PasswordResetSent';
import PasswordResetSuccess from './components/account/PasswordResetSuccess';
import PasswordResetFailure from './components/account/PasswordResetFailure';
import TenderDetails from './components/TenderDetails';
import PasswordReset from './components/account/PasswordReset';

const App = () => {
    return (
        <Switch>
            {/* Login, Register and Account management */}
            <Redirect exact path='/' to='/sign-in' />

            <Route exact path='/sign-in' component={Login} />
            <Route exact path='/register' component={Register} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/password-reset' component={PasswordReset} />
            <Route exact path='/password-sent' component={PasswordResetSent} />
            <Route exact path='/password-success' component={PasswordResetSuccess} />
            <Route exact path='/password-failure' component={PasswordResetFailure} />

            {/* Authenticated Pages */}
            <Switch>  
                <LayoutRoute exact path='/search' layout={Layout} component={Search} />
                <LayoutRoute exact path='/search-results' layout={Layout} component={ListPage} />
                <LayoutRoute exact path='/tender-details/:guid' layout={Layout} component={TenderDetails} />
            </Switch>
        </Switch>
    );
}

export default App;
